<template>
  <div class="share-btn">

    <template v-if="isSmall">
      <div v-if="!isLoading" class="badge btn-linkedin" @click="buildSharingLink">
        <linkedin-icon size="15"/>
        <!-- Share -->
      </div>
      <div v-else class="badge btn-linkedin" disabled>
        <span class="me-2">
          <linkedin-icon size="15"/>
        </span>
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
        <span class="visually-hidden">Loading...</span>
      </div>
    </template>
    <template v-else>
      <button v-if="!isLoading" class="btn btn-linkedin btn-sm" @click="buildSharingLink">
        <linkedin-icon size="15"/>
        <!-- Share -->
      </button>
      <button v-else class="btn btn-linkedin btn-sm" disabled>
      <span class="me-2">
        <linkedin-icon size="15"/>
      </span>
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
        <span class="visually-hidden">Loading...</span>
      </button>
    </template>
  </div>
</template>

<script>
import axios from "@/axios";
import { LinkedinIcon } from 'vue-feather-icons';
import { mapGetters } from "vuex";

export default {
  name: "ArticleLinkedIn",
  components: {LinkedinIcon},

  props: {
    summary: String,
    link: String,
    article: Object,
    isSmall: Boolean
  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    ...mapGetters("accounts", ["getCurrentUser"]),
    shareOnLinkedIn(sharingUrl, shortUrl) {
      this.sendDataToMixPannel(sharingUrl, shortUrl)
    },
    sendDataToMixPannel(sharingUrl, shortUrl) {
      let user = this.getCurrentUser();

      this.$tracking.trackEvent("LinkedIn Event", {
        "User Name": user.name,
        "Article Title": this.article.title,
        'campaign_type': "Twitter",
        "Article Summary": this.article.summary,
        "shortenedUrl": shortUrl,
      });
      const url = `https://www.linkedin.com/shareArticle?mini=true&url=${sharingUrl}&title=PoweredbyRegAlytics&summary=Information provided by RegAlytics&source=RegAlytics.ai`;
      window.open(url, 'linkedin_window', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0')
    },

    // Builds the short URL for use with tracking
    buildSharingLink() {
      this.isLoading = true;
      axios.post('/telemetry/create-sort-url', {
        article_id: this.article.id,
        campaign_type: 'LinkedIn'
      }, {withCredentials: true}).then(response => {
        const shortUrl = response.data['short_url'];
        const finalUrl = `${window.location.origin}/link${shortUrl}`;
        this.shareOnLinkedIn(finalUrl, shortUrl);
        this.isLoading = false;
      }).catch(() => {
        this.$store.dispatch('notifications/addNotification', {
          message: 'There was an error sharing this article! Please try again',
          type: 'danger'
        });
        this.isLoading = false;
      });

    }

  }


}
</script>

<style lang="scss" scoped>
.btn-linkedin {
  background-color: #196ec6;
  color: white;

  &:hover {
    background-color: #145da9;
  }
}

.share-btn {
  cursor: pointer;
}
</style>
