<template>
  <div>
    <article-report-issue v-if="isReporting" :article="res" @updateReportModal="showReportModal" />

    <div class="modal-header-top">
      <button aria-label="Close" class="btn-close" type="button" @click="closeModal"></button>
      <div class="modal-header text-left">
        <h4 v-html="normal_title(res.title)"></h4>
      </div>
    </div>

    <div class="modal-container">
      <div class="modal-body">
        <div class="mb-2 pb-0">
          <span v-for="(agency, index) of allAgencies" :key="index" style="font-size: 0.9rem">
            <a :href="agency.link" target="_blank">{{ agency.name }} </a>
            <span v-if="allAgencies.length > 1 && index === 0"> and </span>
            <span v-if="index !== 0 && agency !== allAgencies[allAgencies.length - 1]
            ">
              and
            </span>
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-0 pb-0">
          <p class="mb-1">
            <span v-if="res.alert_type">{{ res.alert_type }}</span>
          </p>
          <p class="mb-1">
            <b>Last updated:</b> {{ getDateFormat(this.res.latest_update) }}
          </p>
        </div>
        <hr class="mt-0 pt-0" />
        <div class="row">
          <div class="col-lg-8 mb-5 mb-lg-0 custom-modal-content">
            <h5>Summary
              <b-button v-b-tooltip.hover
                title="This alert features content that was generated in part by generative AI with human review."
                class="btn-link btn-sm p-0 m-0 tooltip-button" @click="goBackPage">
                <img src="@/assets/images/ai-gen.png" alt="AI Logo" width="24">
              </b-button>
            </h5>
            <span v-html="normal_summary(res.summary)"></span>
            <div style="margin-top: 60px; margin-bottom: 60px; max-height: 150px; overflow-y: auto;"
              v-if="related_articles && related_articles.length > 0">
              {{ related_articles.length }} Related Articles
              <div style="max-height: 140px;">
                <h5>Related Articles</h5>
                <p v-for="related_article in related_articles" :key="related_article.id" class="mx-1">
                  <a :href="create_related_url(related_article.regalytics_alert_id)" target="_blank">
                    {{ related_article.latest_update }} - {{ related_article.title }}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <h5 class="custom-modal-title-item">Article Information</h5>
            <ul>
              <li v-if="res.status" class="d-flex justify-content-between">
                <label>Status</label>
                {{ res.status }}
              </li>
              <li v-if="res.classification" class="d-flex justify-content-between">
                <label>Agency Classification</label>
                {{ res.classification }}
              </li>
              <li v-if="res.filing_type" class="d-flex justify-content-between">
                <label>Filing Type</label>
                {{ res.filing_type }}
              </li>
              <li v-if="res.in_federal_register" class="d-flex justify-content-between">
                <label>In Federal Register</label>
                {{ res.in_federal_register }}
              </li>
              <li v-if="res.docket_file_number" class="d-flex justify-content-between">
                <label>Docket File Number</label>
                {{ res.docket_file_number }}
              </li>
              <li v-if="res.order_notice" class="d-flex justify-content-between">
                <label>Order Notice ID</label>
                {{ res.order_notice }}
              </li>
              <li v-if="res.sec_release_number" class="d-flex justify-content-between">
                <label>SEC Release Number</label>
                {{ res.sec_release_number }}
              </li>
              <li v-if="res.federal_register_publication_date" class="d-flex justify-content-between">
                <label>Published on</label>
                {{ getDateFormat(res.federal_register_publication_date) }}
              </li>
            </ul>
            <hr />
            <h5 class="custom-modal-title-item">Important Dates</h5>
            <ul>
              <li v-if="res.original_publication_date" class="d-flex justify-content-between">
                <label>Originally Published</label>
                {{ getDateFormat(res.original_publication_date) }}
              </li>

              <li v-if="res.rule_effective_date" class="d-flex justify-content-between">
                <label>Rule Effective</label>
                {{ getDateFormat(res.rule_effective_date) }}
              </li>

              <li v-if="res.proposed_comments_due_date" class="d-flex justify-content-between">
                <label>Comments Due</label>
                {{ getDateFormat(res.proposed_comments_due_date) }}
              </li>
            </ul>
            <hr v-if="allSectors.length > 0" />
            <h5 class="custom-modal-title-item" v-if="res.sector_type.length > 0">
              Sectors
            </h5>
            <p>
              <span v-for="sector in allSectors" :key="sector" class="badge badge-light mx-1"
                style="background: #4a3288 !important">
                <a :href="sector.link" style="text-decoration: none; color: white" target="_blank">{{ sector.name }}
                </a>
              </span>
            </p>
            <hr v-if="allTags.length > 0" />
            <h5 class="custom-modal-title-item" v-if="allTags.length > 0">
              Tags
            </h5>
            <p>
              <span v-for="tag in allTags" :key="tag" class="badge badge-light mx-1"
                style="background: #4a3288 !important">
                <a :href="tag.link" style="text-decoration: none; color: white" target="_blank">{{ tag.name }}
                </a></span>
            </p>
            <hr v-if="res.subtype_classification.length > 0" />
            <h5 class="custom-modal-title-item" v-if="allSubTypeClassification.length > 0">
              Alert Subclassification
            </h5>
            <p>
              <span v-for="sub_type in allSubTypeClassification" :key="sub_type" class="badge badge-light mx-1"
                style="background: #4a3288 !important">
                <a :href="sub_type.link" style="text-decoration: none; color: white" target="_blank">{{ sub_type.name }}
                </a></span>
            </p>
            <hr v-if="res.subtype_classification.length > 0" />
            <h5 class="custom-modal-title-item" v-if="allHigherOrderAlertClassification.length > 0">
              Alert Classification
            </h5>
            <p>
              <span v-for="sub_type in allHigherOrderAlertClassification" :key="sub_type" class="badge badge-light mx-1"
                style="background: #4a3288 !important">
                <a :href="sub_type.link" style="text-decoration: none; color: white" target="_blank">{{ sub_type.name }}
                </a></span>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex social-footer-mb">
          <article-linked-in :article="res" :link="res.pdf_url" :summary="res.summary" class="me-2" />
          <article-tweet :article="res" :link="res.pdf_url" :summary="res.summary" class="me-2" />
          <a class="btn btn-green btn-sm me-2" @click="() => goToSource(res)">
            <link-icon size="15" />
            Go to Source
          </a>
          <a class="btn btn-green btn-sm me-2" @click="() => mailToLink(res)">
            <mail-icon size="15" />
            Mail
          </a>
          <b-button v-if="calendarOption(res)" :id="'popover-target-' + res.id" class="btn btn-green btn-sm">
            <calendar-icon size="15" />
            Add To Calendar
          </b-button>

          <b-popover :target="'popover-target-' + res.id" triggers="click" placement="bottomleft">
            <div class="d-flex flex-column justify-content-start mb-3">
              <div v-if="res.rule_effective_date && res.rule_effective_date != 'None'" class="d-flex align-items-center justify-content-start">
                <input class="me-2 d-inline-block" type="radio" id="effectiveDate" name="calendarInput" v-model="selectedEventType" value="Effective Date" />
                <label for="effectiveDate">Effective Date ({{ res.rule_effective_date }})</label>
              </div>
              <div v-if="res.proposed_comments_due_date && res.proposed_comments_due_date != 'None'" class="d-flex align-items-center justify-content-start">
                <input class="me-2 d-inline-block" type="radio" id="commentsDueDate" name="calendarInput" v-model="selectedEventType" value="Comments Due Date" />
                <label for="commentsDueDate">Comments Due Date ({{ res.proposed_comments_due_date }})</label>
              </div>
              <div v-if="res.event_start_date && res.event_start_date != 'None'" class="d-flex align-items-center justify-content-start">
                <input class="me-2 d-inline-block" type="radio" id="eventDate" name="calendarInput" v-model="selectedEventType" value="Event Date" />
                <label for="eventDate">Event Date ({{ res.event_start_date }})</label>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-start">
              <div @click="addToCalendar('google', res)" class="calendar-icons-container">
                <img src="@/assets/calendar-icons/google.webp" alt="Google Calendar" class="calendar-icons" />
                <small>Google</small>
              </div>
              <div @click="addToCalendar('outlook', res)" class="calendar-icons-container">
                <img src="@/assets/calendar-icons/outlook.webp" alt="Outlook Calendar" class="calendar-icons" />
                <small>Outlook</small>
              </div>
              <div @click="addToCalendar('ical', res)" class="calendar-icons-container">
                <img src="@/assets/calendar-icons/ical.webp" alt="iCal Calendar" class="calendar-icons" />
                <small>iCal</small>
              </div>
              <div @click="addToCalendar('yahoo', res)" class="calendar-icons-container mr-0">
                <img src="@/assets/calendar-icons/yahoo.webp" alt="Yahoo Calendar" class="calendar-icons" />
                <small>Yahoo</small>
              </div>
            </div>
          </b-popover>
        </div>
        <div class="d-flex align-items-center">
          <article-card-save :article="res" :left="true" class="me-3" />
          <p class="mb-0" style="cursor: pointer" title="Report an issue" @click="isReporting = true">
            <flag-icon />
          </p>

          <p class="mb-0 link-primary" style="cursor: pointer" @click="isReporting = true"></p>
          <!--          <article-modal-report-issue :article="res" />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { BPopover } from 'bootstrap-vue';
import { google, yahoo, ics, office365 } from 'calendar-link';
import ArticleLinkedIn from "@/components/articles/article-modal/ArticleLinkedIn";
import ArticleTweet from "@/components/articles/article-modal/ArticleTweet";
import ArticleReportIssue from "@/components/articles/article-modal/article-report-issue/ArticleReportIssue";
import ArticleCardSave from "@/components/articles/articles-card/article-card-save/ArticleCardSave";
import { getLongDisplayFormat } from "@/helpers/dateHelper";
import { createAgenciesString } from "@/helpers/stringHelpers";
import { fetch_related_articles } from "@/store/services/articleService";
import { CalendarIcon, FlagIcon, LinkIcon, LogOutIcon, MailIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";

export default {
  name: "ArticleModalContent",
  components: {
    BPopover,
    ArticleReportIssue,
    ArticleCardSave,
    ArticleLinkedIn,
    ArticleTweet,
    LinkIcon,
    FlagIcon,
    MailIcon,
    CalendarIcon,
  },

  props: ["res"],

  data() {
    return {
      allAgencies: [],
      allSectors: [],
      related_articles: [],
      allTags: [],
      allSubTypeClassification: [],
      allHigherOrderAlertClassification: [],
      isReporting: false,
      selectedEventType: ''
    };
  },
  created() {
    fetch_related_articles(this.res.id).then((response) => {
      this.related_articles = response.data;
    });
  },

  methods: {
    ...mapGetters("accounts", ["getCurrentUser"]),
    getDateFormat(dateString) {
      return getLongDisplayFormat(dateString);
    },
    calendarOption(r) {
      if ((r.proposed_comments_due_date && r.proposed_comments_due_date != 'None') || (r.event_start_date && r.event_start_date != 'None') || (r.event_end_date && r.event_end_date != 'None') || (r.rule_effective_date && r.rule_effective_date != 'None')) return true
      else return false
    },
    addToCalendar(service, data) {
      if (!this.selectedEventType || this.selectedEventType == '') {
        this.$store.dispatch('notifications/pushNotification', { message: 'Event type not selected. Please try again!', type: 'danger' });
        return;
      }

      let eventStart;

      switch (this.selectedEventType) {
        case 'Effective Date':
          eventStart = data.rule_effective_date;
          break;
        case 'Comments Due Date':
          eventStart = data.proposed_comments_due_date;
          break;
        case 'Event Date':
          if (data.event_start_date) eventStart = data.event_start_date;
          else eventStart = data.event_end_date;
          break;
      }
      
      let pdf = data.pdf_url ? data.pdf_url : '';
      let description = data.summary + '\n\n <a href="' + pdf + '"target="_blank">' + pdf + '</a>';

      const event = {
        title: data.title,
        description: description,
        location: data.location,
        start: eventStart,
        allDay: true,
      };

      let calendarLink;
      switch (service) {
        case 'google':
          calendarLink = google(event);
          break;
        case 'outlook':
          calendarLink = office365(event);
          break;
        case 'ical':
          calendarLink = ics(event);
          break;
        case 'yahoo':
          calendarLink = yahoo(event);
          calendarLink = calendarLink.replace(/&et=[^&]*/, ''); // Remove the 'et' parameter to create an all-day event
          break;
        default:
          return;
      }

      window.open(calendarLink, '_blank');

      if (data.regalytics_alert_id && data.regalytics_alert_id != 'None') {
        let url = 'dummy';
        axios.post(url, { regalytics_alert_id: data.regalytics_alert_id }).then((response) => {
          console.log(response);
        })
      }
    },
    goBackPage() {
      const routeData = this.$router.resolve({ name: 'AiDisclaimer' });
      window.open(routeData.href, '_blank');
    },
    generate_agency_for_related_articles(agencies) {
      let commaSeparatedNames = ""; // initialize an empty string to hold the comma-separated names

      for (let i = 0; i < agencies.length; i++) {
        const name = agencies[i].name; // fetch the name property from the current object in the array
        commaSeparatedNames += name + ","; // add the name and a comma to the end of the string
      }
      return commaSeparatedNames;
    },
    normal_title(title) {
      let search_terms = this.$store.getters[
        "search/getAllIncludeKeywords"
      ].concat(this.$store.getters["search/getAllOrIncludeKeywords"]);
      if (search_terms.length <= 0) {
        return title;
      }
      let current_user = this.$store.getters["accounts/getCurrentUser"];
      if (current_user.search_highlight) {
        var regexFromMyArray = new RegExp(search_terms.join("|"), "gi");
        return title.replace(regexFromMyArray, (match) => {
          var colors = ["yellow"];
          var random_color = colors[Math.floor(Math.random() * colors.length)];
          return (
            '<span style="background: ' +
            random_color +
            '">' +
            match +
            "</span>"
          );
        });
      } else {
        return title;
      }
    },
    normal_summary(summary) {
      let search_terms = this.$store.getters[
        "search/getAllIncludeKeywords"
      ].concat(this.$store.getters["search/getAllOrIncludeKeywords"]);
      if (search_terms.length <= 0) {
        return summary;
      }
      let current_user = this.$store.getters["accounts/getCurrentUser"];
      if (current_user.search_highlight) {
        var regexFromMyArray = new RegExp(search_terms.join("|"), "gi");
        return summary.replace(regexFromMyArray, (match) => {
          var colors = ["yellow"];
          var random_color = colors[Math.floor(Math.random() * colors.length)];
          return (
            '<span style="background: ' +
            random_color +
            '">' +
            match +
            "</span>"
          );
        });
      } else {
        return summary;
      }
    },
    // Creates the link for every agency listed in an article modal
    createAgencyLinks() {
      const url = new URL(document.location.href);

      let newAgenciesList = [];
      this.res.agencies.forEach((agency) => {
        const encodedParams = encodeURI(agency.name);
        let newUrl = `${url.origin}/dashboard/articles?agencies=${encodedParams}`;
        newAgenciesList.push({
          name: agency.name,
          link: newUrl,
        });
      });
      console.log(newAgenciesList, "newAgenciesList");
      this.allAgencies = newAgenciesList;
    },
    createAllSubTypeClassificationLinks() {
      const url = new URL(document.location.href);

      let newSubTypeClassification = [];
      this.res.subtype_classification.forEach((sub_type) => {
        const encodedParams = encodeURI(sub_type.name);
        let newUrl = `${url.origin}/dashboard/articles?subtype_classification=${encodedParams}`;
        newSubTypeClassification.push({
          name: sub_type.name,
          link: newUrl,
        });
      });
      console.log(newSubTypeClassification, "newSubTypeClassification");
      this.allSubTypeClassification = newSubTypeClassification;
    },
    createAllHigherOrderAlertClassificationLinks() {
      const url = new URL(document.location.href);

      let newHigherOrderAlertClassification = [];
      this.res.subtype_classification.forEach((higher_order_alert_class) => {
        const encodedParams = encodeURI(
          higher_order_alert_class.higher_order_alert_classification.name
        );
        let newUrl = `${url.origin}/dashboard/articles?higher_order_alert_classification=${encodedParams}`;
        newHigherOrderAlertClassification.push({
          name: higher_order_alert_class.higher_order_alert_classification.name,
          link: newUrl,
        });
      });

      // Remove duplicates
      const unique = new Map();
      newHigherOrderAlertClassification.forEach(item => {
        if (!unique.has(item.name)) {
          unique.set(item.name, item);
        }
      });

      this.allHigherOrderAlertClassification = Array.from(unique.values());
    },
    createSectorLinks() {
      const url = new URL(document.location.href);

      let newSectorsList = [];
      this.res.sector_type.forEach((sector) => {
        const encodedParams = encodeURI(sector.name);
        let newUrl = `${url.origin}/dashboard/articles?sector_type=${encodedParams}`;
        newSectorsList.push({
          name: sector.name,
          link: newUrl,
        });
      });
      console.log(newSectorsList, "allHigherOrderAlertClassifinewSectorsListcation");

      this.allSectors = newSectorsList;
    },
    create_related_url(regAlyticsAlertID) {
      const url = new URL(document.location.href);
      let newUrl = `${url.origin}/dashboard/articles?regAlyticsAlertID=${regAlyticsAlertID}`;
      return newUrl;
    },
    createTagsLinks() {
      const url = new URL(document.location.href);

      let newTagsList = [];
      this.res.tags.forEach((tag) => {
        const encodedParams = encodeURI(tag.name);
        let newUrl = `${url.origin}/dashboard/articles?tags=${encodedParams}`;
        newTagsList.push({
          name: tag.name,
          link: newUrl,
        });
      });
      console.log(newTagsList, "newTagsList");
      this.allTags = newTagsList;
    },
    closeModal() {
      this.$store.dispatch("articles/updateModalShowingState", false);
    },
    goToSource(article) {
      this.trackGoToSource(article);
      window.open(article.pdf_url, "_blank");
    },
    trackGoToSource(article) {
      this.$tracking.trackEvent("ViewArticleSource", {
        id: article.id,
        regalytics_alert_id: article.regalytics_alert_id,
        title: article.title,
        summary: article.summary,
      });
    },
    showReportModal(value) {
      this.isReporting = value;
    },
    mailTo(urlLink, article) {
      let article_summary = article.summary.substring(0, 300) + "...";
      let user = this.getCurrentUser();
      let publicLink = urlLink;
      let article_title = article.title;
      let agency_names = article.agencies;

      //To track mail article event to mixpanel
      this.$tracking.trackEvent("Mail Article", {
        "User Name": user.name,
        "Article Title": article_title,
        Agencies: this.createAgenciesString(agency_names),
        "Share Url": publicLink,
      });

      window.open(
        "mailto:?subject= Saw this regulatory alert and thought of you...&body=Saw this regulatory alert courtesy of my RegAlytics subscription and thought of you!%0D%0A%0D%0ASincerely,%0D%0A" +
        user.name +
        "%0D%0A%0D%0A" +
        article_title +
        "%0D%0A" +
        this.createAgenciesString(agency_names) +
        "%0D%0A%0D%0A" +
        article_summary +
        "%0D%0A%0D%0A" +
        publicLink +
        "%0D%0A%0D%0ASign up for RegAlytics and make regulation easy!" +
        "%0D%0Ahttps://www.RegAlytics.ai",
        "_blank"
      );
    },
    createAgenciesString(allAgencies) {
      return createAgenciesString(allAgencies);
    },
    // Builds the short URL for use with tracking
    mailToLink(article) {
      axios
        .post(
          "/telemetry/create-sort-url",
          {
            regalytics_alert_id: article.regalytics_alert_id,
            campaign_type: "EMAIL",
          },
          { withCredentials: true }
        )
        .then((response) => {
          const shortUrl = response.data["short_url"];
          const finalUrl = `${window.location.origin}/link${shortUrl}`;
          console.log(finalUrl);
          this.mailTo(finalUrl, article);
        })
        .catch(() => {
          this.$store.dispatch("notifications/addNotification", {
            message:
              "There was an error sharing this article! Please try again",
            type: "danger",
          });
        });
    },
  },

  mounted() {
    this.createAgencyLinks();
    this.createTagsLinks();
    this.createSectorLinks();
    this.createAllSubTypeClassificationLinks();
    this.createAllHigherOrderAlertClassificationLinks();
  },
};
</script>

<style lang="scss" scoped>
.tooltip-button {
  cursor: pointer;
}

.modal-container {
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding: 5px 16px;
}

.btn-close {
  position: absolute;
  right: 5px;
}

.modal-header-top {
  font-family: "Fira Sans", sans-serif;
  border-bottom: 3px solid #4a3288;
  padding: 3px 1px;
  margin: 0 30px;

  .modal-header {
    border: 0;
    padding-left: 0;
    padding-top: 20px;

    h4 {
      font-weight: 400 !important;
      line-height: 1.5;
      font-size: 1.25rem;
      margin: 0;
      padding: 0;
    }
  }
}

.custom-modal-title-item {
  line-height: 1.4rem;
  font-weight: 600;
}

.custom-modal-content {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.4rem;

  h5 {
    font-weight: 600;
  }
}

.modal-body ul {
  margin: 0;
  padding: 0;
}

.modal-body li {
  list-style: none;
}

.modal-body li label {
  font-weight: 700;
}

.label-fw-bold {
  font-weight: 700;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.btn-view-source {
  background-color: #4a3288;
  color: white;

  &:hover {
    background-color: #688627;
  }
}
</style>
