
// Shortens the string to the last word in a sentence
function truncateString(yourString, maxLength) {
    // get the index of space after maxLength
    const index = yourString.indexOf(" ", maxLength);
    return index === -1 ? yourString : yourString.substring(0, index);
}

// Create the string of agencies separated by a comma
// If they hit the required length of greater than one
function createAgenciesString(allAgencies) {
    let agenciesString = '';
    if(allAgencies.length>0){
        for (let i = 0; i < allAgencies.length; i++) {
            i === 0 ?
                agenciesString = `${agenciesString} ${allAgencies[i].name}`
                :
                agenciesString = `${agenciesString} and ${allAgencies[i].name}`;
        }

    }
    return agenciesString;

}


export {
    truncateString,
    createAgenciesString
}
