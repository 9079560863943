<template>
  <div class="modal-container" id="reportModal" @click="closeModal">
    <div class="modal-content">

      <!-- Start of Modal title -->
      <div class="modal-header">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h5>Report Article</h5>
          <button aria-label="Close" class="btn-close" type="button" @click="hideModal"></button>
        </div>
      </div>
      <!-- End of Modal title -->

      <div class="container mt-3 mb-2">
        <div>
          <p>
            <span class="fw-bold">RegAlytics Alert ID:</span> {{ article.regalytics_alert_id }}
          </p>
        </div>
        <div v-if="isLoading">
          <spinner/>
        </div>
        <form v-else @submit.prevent="reportArticle">
          <div class="mb-3">
            <label for="summary">Whats the problem?</label>
            <textarea id="summary" class="form-control" v-model="articleIssue"
                      :maxlength="maxCount"
                      @keyup="updateCount"
                      required></textarea>
            <span id="count_message" class="pull-right label label-default"> {{ count }} / {{ maxCount }} </span>
            <br>
          </div>
          <div class="mb-3">
            <button type="submit" class="btn btn-green w-100">Report Article</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { sendSupportMessage } from "@/store/services/accountService";
import Spinner from "@/components/Spinner";

export default {
  name: "ArticleReportIssue",
  components: {Spinner},
  props: {
    article: Object
  },

  computed: {
    ...mapGetters('accounts', ['getCurrentUser'])
  },

  data() {
    return {
      articleIssue: '',
      count: 0,
      maxCount: 450,
      isLoading: false
    }
  },

  methods: {
    updateCount(){
      var text_length = this.articleIssue.length;
      this.count = text_length
    },
    hideModal() {
      this.$emit('updateReportModal', false);
    },

    closeModal(event) {
      if (event.target.id === 'reportModal') {
        this.hideModal();
      }
    },

    reportArticle() {
      this.isLoading = true;
      const currentEmail = this.getCurrentUser.email;
      console.log(currentEmail)
      const subject = "Report an Article";
      const message = `${this.articleIssue} </br>RegAlytics Alert ID: ${this.article.regalytics_alert_id}`;

      // Sends the support message via store
      sendSupportMessage(currentEmail, subject, message)
      .then(response => {
        if (response.request.status === 201) {
          this.$store.dispatch('notifications/pushNotification', {message: 'Thanks! We will review the article and be in touch shortly', type: 'green'});

          // Tracks the event with Segment
          this.$tracking.trackEvent('reportedArticle', {
            email: currentEmail,
            subject: subject,
            body: message
          });
          this.articleIssue = '';
          this.isLoading = false;
        }
      })
      .catch(() => {
        this.$store.dispatch('notifications/pushNotification', {message: 'There was an error reporting this article. Please try again!', type: 'danger'});
        this.isLoading = false;
      });
    }

  }

}
</script>

<style scoped lang="scss">
.modal-container {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.modal-content {
  position: relative;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
  background: white;
  margin: 0 0;
  width: 100% !important;
  height: 100%;
  overflow: auto;

  @media (min-width: 991px) {
    margin: 3% auto; /* 15% from the top and centered */
    width: 80%;
    max-width: 900px;
    height: fit-content;
    overflow: revert;
  }

  /* Animate the content of the modal */
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s
}

.modal-header {
  position: relative;
  top: 0;
  right: 0;
  padding-bottom: 6px;
  border-bottom: 3px solid #4a3288;
  align-items: center;
}

@-webkit-keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}
textarea {
  resize: none;
}

#count_message {
  background-color: gray;
  margin-top: 0px;
  margin-right: 0px;
  float: right;
  padding: 0px;
  width: 70px;
  color: white;
  text-align: center;
}
</style>
