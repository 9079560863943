<template>
  <div class="share-btn">

    <template v-if="isSmall">
      <div v-if="!isLoading" class="badge btn-twitter" @click="buildSharingLink">
        <!-- <twitter-icon size="15"/> -->
        <img src="@/assets/images/x-twitter.svg" alt="Twitter Logo" width="16" >
        <!-- Tweet -->
      </div>
      <div v-else class="badge btn-twitter" disabled>
      <span class="me-2">
        <!-- <twitter-icon size="15"/> -->
        <img src="@/assets/images/x-twitter.svg" alt="Twitter Logo" width="16" >
      </span>
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
        <span class="visually-hidden">Loading...</span>
      </div>
    </template>
    <template v-else>
      <button v-if="!isLoading" class="btn btn-twitter btn-sm" @click="buildSharingLink">
        <img src="@/assets/images/x-twitter.svg" alt="Twitter Logo" width="16" >
        <!-- Tweet -->
      </button>
      <button v-else class="btn btn-twitter btn-sm" disabled>
      <span class="me-2">
        <img src="@/assets/images/x-twitter.svg" alt="Twitter Logo" width="16" >
      </span>
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
        <span class="visually-hidden">Loading...</span>
      </button>
    </template>

  </div>
</template>

<script>
// import { TwitterIcon } from 'vue-feather-icons';

import axios from "@/axios";
import { mapGetters } from "vuex";

export default {
  name: "ArticleTweet",

  components: {
    // TwitterIcon
  },

  computed: {

    calculateSummaryText() {
      const summaryText = this.truncateString(this.summary, 200)
      let finalText;
      if (this.summary && this.summary.length > 200) {
        finalText = `${summaryText}... via @RegAlytics`;
      } else {
        finalText = `${summaryText} via @RegAlytics`;
      }
      return finalText;
    },

  },

  props: {
    summary: String,
    link: String,
    article: Object,
    isSmall: Boolean
  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    ...mapGetters("accounts", ["getCurrentUser"]),

    // Shortens the string to the last word in a sentence
    truncateString(yourString, maxLength) {
      // get the index of space after maxLength
      const index = yourString.indexOf(" ", maxLength);
      return index === -1 ? yourString : yourString.substring(0, index)
    },

    shareOnTwitter(sharingUrl, shortUrl) {
      this.sendDataToMixPannel(sharingUrl, shortUrl)

    },

    sendDataToMixPannel(sharingUrl, shortUrl){
      let user = this.getCurrentUser();

      this.$tracking.trackEvent("Tweet Event", {
        "User Name": user.name,
        "Article Title": this.article.title,
        'campaign_type': "Twitter",
        "Article Summary": this.article.summary,
        "shortenedUrl": shortUrl,
      });
      const url = `https://twitter.com/intent/tweet?text=${this.calculateSummaryText}&url=${sharingUrl}`;
      window.open(url, 'twitter_window', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0')
    },

    // Builds the short URL for use with tracking
    buildSharingLink() {
      this.isLoading = true;
      axios.post('/telemetry/create-sort-url', {
        regalytics_alert_id: this.article.regalytics_alert_id,
        campaign_type: 'Twitter'
      }, {withCredentials: true}).then(response => {
        const shortUrl = response.data['short_url'];
        const finalUrl = `${window.location.origin}/link${shortUrl}`;
        this.shareOnTwitter(finalUrl, shortUrl);
        this.isLoading = false;
      }).catch(() => {
        this.$store.dispatch('notifications/addNotification', {
          message: 'There was an error sharing this article! Please try again',
          type: 'danger'
        });
        this.isLoading = false;
      });

    }

  }

}
</script>

<style lang="scss" scoped>
.btn-twitter {
  background-color: #bcbec0;
  color: white;

  &:hover {
    background-color: #81878a;
  }

}

.share-btn {
  cursor: pointer;
}
</style>
