<template>
  <div class="modal-container" id="customModal" @click="event => closeModal(event)">
    <div class="modal-content">
      <article-modal-content :res="getModalDetails"/>
    </div>
  </div>
</template>

<script>
import ArticleModalContent from "@/components/articles/article-modal/ArticleModalContent";
import {mapGetters} from "vuex";

export default {
  name: "ArticleModal",
  components: {ArticleModalContent},

  computed: {
    ...mapGetters('articles', ['getModalDetails']),
  },

  methods: {
    closeModal(event) {
      if (event.target.id === 'customModal') {
        this.$store.dispatch('articles/updateModalShowingState', false);
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.modal-container {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.modal-content {
  position: relative;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
  background: white;
  margin: 0 0;
  width: 100% !important;
  height: 100%;
  overflow: auto;

  @media (min-width: 991px) {
    margin: 3% auto; /* 15% from the top and centered */
    width: 80%;
    max-width: 900px;
    height: fit-content;
    overflow: revert;
  }

  /* Animate the content of the modal */
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s
}

@-webkit-keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}
</style>