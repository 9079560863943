<template>
  <div v-if="onArticlesRoute" class="article-card-save ms-2" @mouseenter="showPopover" @mouseleave="hidePopover">
    <folder-plus-icon/>

    <div v-if="showingPopover" class="save-card" :class="determineStyle">
      <div class="d-flex align-items-center justify-content-between">
        <span class="fw-bold">Add to Folder</span>
        <span class="btn btn-sm btn-link me-0 pe-0 pt-0 mt-0" @click="() => setShowingModal(true)">Create</span>
      </div>
      <hr class="m-0 mb-1 p-0">

      <div v-for="folder in getAllFolders" :key="folder.id">
        <article-card-save-row :article="article" :folder="folder" />
      </div>

    </div>
  </div>
</template>

<script>
import {FolderPlusIcon} from "vue-feather-icons";
import {mapActions, mapGetters} from "vuex";
import ArticleCardSaveRow
  from "@/components/articles/articles-card/article-card-save/article-card-save-row/ArticleCardSaveRow";


export default {
  name: "ArticleCardSave",
  components: {
    ArticleCardSaveRow,
    FolderPlusIcon,
  },

  computed: {
    ...mapGetters('folders', ['getAllFolders']),

    determineStyle() {
      return this.left ? 'save-card--left' : 'save-card--right';
    }

  },

  props: {
    article: Object,
    left: Boolean
  },


  data() {
    return {
      showingPopover: false,
      onArticlesRoute: true
    }
  },

  methods: {
    ...mapActions('folders', ['setShowingModal']),

    showPopover() {
      this.showingPopover = true;
    },

    hidePopover() {
      this.showingPopover = false;
    },

    // Used to only show on the Articles route
    hasArticlesRoute() {
      return this.$route.name === "Articles";
    }

  },

  created() {
    this.onArticlesRoute = this.hasArticlesRoute();
  }

}
</script>

<style lang="scss" scoped>
.article-card-save {
  position: relative;

  .save-card {
    position: absolute;
    min-width: 200px;
    max-width: 250px;
    top: 10px;
    overflow: auto;
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 99;
    padding: 5px 6px;
    margin-top: 5px;
  }

  .save-card--left {
    left: 0;
  }

  .save-card--right {
    right: 0;
  }

}
</style>
