export {
    getLongDisplayFormat
}

function getLongDisplayFormat(dateString) {
    let newDate = new Date(dateString);
    const month = newDate.toLocaleString('default', {month: 'long', timeZone: 'UTC'});
    const day = dateString.split('-')[2];
    const year = newDate.getUTCFullYear();
    return `${month} ${day}, ${year}`;
}
