<template>
  <div v-if="insideFolder" class="ms-2">
    <button type="button" class="btn-close btn-sm" @click="removeArticle"></button>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "ArticleCardRemove",

  props: {
    article: Object
  },

  data() {
    return {
      insideFolder: true
    }
  },

  methods: {
    ...mapActions('folders', ['removeArticleFromFolder']),

    isInsideFolder() {
      return this.$route.name === "Folder";
    },

    removeArticle() {
      const folderId = this.$route.params.id;
      const regalyticsalertid = this.article.id;
      this.removeArticleFromFolder({folderId: folderId, regalyticsAlertId: regalyticsalertid});
      this.$tracking.trackEvent('removeFolder')
    }

  },

  created() {
    this.insideFolder = this.isInsideFolder();
  }

}
</script>

<style scoped>

</style>
